<script lang="ts">
    import {AnimatePresence, Motion} from "svelte-motion"
    import {Easing} from "@utils/Easing"

    let className

    export let errors: string[]
    export {className as class}

    $: uniqueErrors = [...new Set(errors)]
</script>

<AnimatePresence show={uniqueErrors.length > 0}>
    <Motion let:motion
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: 1,
            }}
            exit={{
                opacity: 0,
            }}
            transition={{
                type: "timing",
                duration: .5,
                ease: Easing.easeOutQuint,
            }}
    >
        <div use:motion class="{uniqueErrors.length > 0 ? 'mt-1' : ''} flex flex-col relative text-red-700 font-semibold italic {className}">
            <div class="absolute h-0 w-0 -top-40" class:form-type-with-errors={uniqueErrors.length > 0}></div>
            {#each uniqueErrors as error (error)}
                <small>{error}</small>
            {/each}
        </div>
    </Motion>
</AnimatePresence>
